import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@13.5.6_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@13.5.6_@babel+core@7.24.4_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/tools/docker.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/tools/figma.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/tools/k8s.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/tools/mongoDB.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/tools/nextjs.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/tools/react.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/tools/ubuntu.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/tools/vscode.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/AnimatePage/index.ts");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/atoms/TypeWriter/index.ts")